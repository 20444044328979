<script lang="ts" setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import { useRouter } from 'vue-router';
import LogoProSystem from '@/assets/logo.svg?component';
import NotificationCard from '@/components/notifications/notificationCard.vue';
import UserMenuCard from '@/components/userMenu/userMenuCard.vue';
import ThemeButtonComponent from '@/components/buttons/ThemeButtonComponent.vue';
import DotNotificationComponent from '@/components/notifications/components/DotNotificationComponent.vue';
import { useUserSettingsStore } from '@/stores/userSettingsStore';
import { storeToRefs } from 'pinia';
import { firstLetters } from '@/utils/firstLetters';
import { useCentralSettingsStore } from '@/stores/centralSettingsStore';
import { useUnreadNotificationsReportStatusStore } from '@/stores/notifications/reportStatusNotificationsStore';
import { useUnreadNotificationEventWizardStatusStore } from '@/stores/notifications/eventWizardNotificationsStore';
import SystemUpdatesComponent from '@/components/userMenu/SystemUpdatesComponent.vue';
import { truncateString } from '@/utils/truncate/truncateString';
import ModalWindowComponent from '@/components/customComponents/ModalWindowComponent.vue';
import { useDropSettingsStoreStore } from '@/stores/dropSettingsStore';
// import Garland from '@/holidays/newYear/Garland.vue';
// import christmasHat from '@/assets/images/holidays/hat.png';

const notificationsReportStore = useUnreadNotificationsReportStatusStore();
const { reportNotifications } = storeToRefs(notificationsReportStore);
const notificationWizardStore = useUnreadNotificationEventWizardStatusStore();
const { marketingEventsNotifications } = storeToRefs(notificationWizardStore);
const { layoutConfig, layoutState, onMenuToggle } = useLayout();
const userSettings = useUserSettingsStore();
const centralSettings = useCentralSettingsStore();
const { currentUser, isLoading } = storeToRefs(userSettings);

const emit = defineEmits<{
    'onConfig:button': [];
}>();

const props = defineProps<{
    themeTypeChangeAbility: boolean;
}>();

interface ClickEvent extends MouseEvent {
    target: HTMLElement;
}
const dropStore = useDropSettingsStoreStore()
const visibleDialogSystemUpdates = ref(false);

const outsideClickListener = ref<((event: ClickEvent) => void) | null>(null);

const topbarMenuActive = ref<boolean>(false);

const router = useRouter();

onMounted(() => {
    bindOutsideClickListener();
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

const logoUrl = computed(() => {
    return layoutConfig.value.darkTheme ? logoWhite : logoDark;
});

const onTopBarMenuButton = () => {
    topbarMenuActive.value = !topbarMenuActive.value;
};

const onSettingsClick = () => {
    topbarMenuActive.value = false;
    emit('onConfig:button');
};

const topbarMenuClasses = computed(() => {
    return { 'layout-topbar-menu-mobile-active': topbarMenuActive.value };
});

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event: ClickEvent) => {
            if (isOutsideClicked(event)) {
                topbarMenuActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};

const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener.value);
        outsideClickListener.value = null;
    }
};

const isOutsideClicked = (event: ClickEvent): boolean => {
    if (!topbarMenuActive.value) return;
    const sidebarEl = document.querySelector('.layout-topbar-menu');
    const topbarEl = document.querySelector('.layout-topbar-menu-button');
    return !(sidebarEl?.isSameNode(event.target) || sidebarEl?.contains(event.target) || topbarEl?.isSameNode(event.target) || topbarEl?.contains(event.target));
};

const op = ref();
const toggle = (event) => {
    if (Array.isArray(event)) op.value.toggle(event[0]);
    else op.value.toggle(event);
};

const userMenu = ref();
const userMenuActive = ref(false);
const checkedTheme = ref(layoutConfig.value.darkTheme);
const onClickUserMenu = (event) => {
    userMenuActive.value = !!event;
    userMenu.value.toggle(event);
};

const saveSettings = (colorTheme: string, darkTheme: boolean) => {
    centralSettings.updateCentralSettings({ colorTheme: { themeName: colorTheme, darkTheme: darkTheme ? 'dark' : 'light' } });
};
</script>

<template>
    <!--    <Garland />-->
    <div class="w-full h-5rem fixed layout-topbar-container">
        <div v-show="dropStore.modalState">
            <ModalWindowComponent :group="'resetSettings'"  :modal-state="dropStore.modalState" :message="'Вы уверены,что хотите сбросить настройки?'" :detail="'После сброса нужно обязательно перезагрузить страницу'" :confirm-button-label="'Сбросить'" :close-modal-fn="dropStore.closeModal" :confirm-fn="dropStore.confirmResetSettings" :radioButtons="dropStore.radionBtn"/>
        </div>
        
        <div class="layout-topbar">
            <router-link to="/" class="layout-topbar-logo gap-2 w-15rem">
                <!--                <img class="christmas-hat" :src="christmasHat" alt="logo" />-->
                <LogoProSystem class="text-primary" :style="{ width: '35px', height: '35px', fill: 'var(--primary-color)' }" />
                <span class="text-primary"><strong>ПРОСИСТЕМ</strong></span>
            </router-link>

            <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle()">
                <i class="pi pi-bars"></i>
            </button>

            <button class="p-link layout-topbar-menu-button layout-topbar-button" @click="onTopBarMenuButton()">
                <i class="pi pi-ellipsis-v"></i>
            </button>

            <div class="layout-topbar-menu align-items-center lg:gap-3" :class="topbarMenuClasses">
                <transition name="theme-button">
                    <ThemeButtonComponent @theme:save="saveSettings" v-if="themeTypeChangeAbility" />
                </transition>
                <button class="p-link layout-topbar-button" @click="toggle">
                    <DotNotificationComponent class="dot-custom-container" v-if="reportNotifications.length || marketingEventsNotifications.length" />
                    <i class="pi pi-bell relative" />
                    <span>Уведомления</span>
                </button>
                <button @click="onSettingsClick()" class="p-link layout-topbar-button">
                    <i class="pi pi-cog"></i>
                    <span>Настройки портала</span>
                </button>
                <div @click="onClickUserMenu" class="flex align-items-center gap-2 lg:my-2 px-3 user-menu border-round" :class="{ active: userMenuActive }">
                    <div class="flex flex-column lg:text-right" style="min-width: 12rem">
                        <Skeleton v-if="isLoading" width="6rem" height="1.2rem" class="mb-2 ml-auto"></Skeleton>
                        <div v-if="!isLoading" class="text-color font-semibold">{{ currentUser?.fullName }}</div>
                        <Skeleton v-if="isLoading" width="12rem" height=".7rem" class="ml-auto"></Skeleton>
                        <div v-if="!isLoading" class="text-sm text-color-secondary font-light">{{ truncateString(currentUser?.email, 28) }}</div>
                    </div>
                    <div class="flex justify-content-center align-items-center gap-2">
                        <Skeleton v-if="isLoading" shape="circle" size="3rem" />
                        <span v-if="!isLoading" class="border-circle w-3rem h-3rem m-2 bg-primary text-gray-900 font-bold flex align-items-center justify-content-center select-none">{{ firstLetters(currentUser.fullName) }}</span>
                        <i class="pi pi-angle-down text-color-secondary" style="font-size: 1rem"></i>
                    </div>
                </div>
                <OverlayPanel @hide="() => (userMenuActive = false)" ref="userMenu" class="text-left p-1 pb-0 w-40rem lg:w-20rem">
                    <UserMenuCard :is-loading="isLoading" :current-user="currentUser" @update:visible="(state: boolean) => (visibleDialogSystemUpdates = state)" @notifications:open="toggle" />
                </OverlayPanel>

                <!--                <button @click="onTopBarMenuButton()" class="p-link layout-topbar-button">-->
                <!--                    <i class="pi pi-user"></i>-->
                <!--                    <span>Профиль пользователя</span>-->
                <!--                </button>-->
            </div>
            <OverlayPanel header="header" ref="op" :pt="{ content: { class: 'p-0' } }">
                <NotificationCard @notification:close="toggle" />
            </OverlayPanel>
        </div>
        <div class="layout-topbar-container__blur" />
        <SystemUpdatesComponent :visible="visibleDialogSystemUpdates" @update:visible="(state: boolean) => (visibleDialogSystemUpdates = state)" header="Изменения в версии 2.0.3" />
    </div>
</template>
<style lang="scss" scoped>
.christmas-hat {
    position: absolute;
    left: 30px;
    top: 12px;
}

.user-menu {
    &:hover {
        background: var(--surface-hover);
    }
    &.active {
        background: rgba(59, 130, 246, 0.24);
    }
}

.dot-custom-container {
    right: 3px;
    top: 3px;
}

.theme-button-enter-from {
    opacity: 0;
}

.theme-button-enter-active {
    transition: opacity 0.5s;
}

.theme-button-enter-to {
    opacity: 1;
}

.theme-button-leave-active {
    opacity: 0;
    transition: 0.5s;
}
</style>
