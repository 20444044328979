import { defineStore } from 'pinia';
import { ref } from 'vue';
import { resetSettings } from '@/api/requests/settings';
import { useToast } from 'primevue/usetoast';


export const useDropSettingsStoreStore = defineStore('dropSettingsStore', () => {
    const toast = useToast()
    //// состояние модалки 
    const modalState = ref<boolean>(false);

    const radionBtn = ref([{
        id: 1,
        name: "Фильтры",
    },
    {
        id: 2,
        name: "Таблицы",
    }])

    function openModal() {
        modalState.value = true;
    }
    function closeModal() {
        modalState.value = false;
    }
    function confirmResetSettings(type: number) {
        const types = {
            1: 'Filter',
            2: 'Table',
        }
        const data = {
            SettingType: types[type as keyof typeof types],
        }
        try {
            resetSettings({ params: { data: data } })
            modalState.value = false
            toast.add({ severity: 'success', summary: 'Настройки успешно сброшены', detail: '', life: 3000 })
        } catch (error) {
            modalState.value = false
            toast.add({ severity: 'error', summary: 'Ошибка', detail: '', life: 3000 })
        }
    }

    return {
        openModal,
        closeModal,
        modalState,
        confirmResetSettings,
        radionBtn
    }
})

