<script setup lang="ts">
import {ref, watch} from 'vue'
import { useToast } from "primevue/usetoast";

const toast = useToast();

const props = defineProps<{
    modalState: boolean,
    group:string,
    message: string,
    group:string,
    confirmButtonLabel?:string,
    closeModalFn: () => void,
    confirmFn?: (value?: number) => void,
    radioButtons?: Array<{id: number, name: string}>,
    detail:string
}>()

const emit = defineEmits(['closeModal'])

const selectedValue = ref<number | null>(null);

const showTemplate = () => {
      toast.add({ 
      severity: 'info', 
      summary: props.message, 
      group: props.group,
      closable: false,
    });
};

watch(()=> props.modalState, () => {
    if(props.modalState === true) {
      showTemplate();
      if (props.radioButtons?.length) {
        selectedValue.value = props.radioButtons[0].id;
      }
    }else{
        toast.removeGroup(props.group);
    }
},{deep:true});
const onClose = () => {
    emit('closeModal')
}

</script>

<template>
    <Toast
        :unstyled="true"
        position="center" 
        :group=props.group 
        @close="onClose" 
        class="custom-toast">
        <template #message="slotProps">
            <div class="toast-content">
                <div class="mainContent">
                    <div class="font-medium text-xl font-bold my-1">Подтверждение сброса пароля</div>
                    <div class="font-medium text-xl my-1 flex items-center flex-items-center">
                        <i class="material-icons-round cursor-pointer">error</i>
                         {{ slotProps.message.summary }}
                    </div>
                    <div class="font-medium text-xl my-1 flex items-center flex-items-center pl-4">{{ detail }}</div>
                    <div v-if="radioButtons" class="radio-group">
                        <div v-for="el in radioButtons" :key="el.id" class="radio-item">
                            <input 
                                type="radio" 
                                :id="'radio-' + el.id"
                                :value="el.id"
                                v-model="selectedValue"
                                :name="'radio-group'"
                            />
                            <label :for="'radio-' + el.id">{{ el.name }}</label>
                        </div>
                    </div>
                    <div class="btnGroup">
                        <Button 
                            size="small" 
                            label="Отменить" 
                            :pt="{
                                root: { 
                                    style: { backgroundColor: 'transparent', border:'none', color:'blue' } 
                                }
                            }"
                            @click="props.closeModalFn">
                        </Button>
                        <Button 
                            v-if="props.confirmButtonLabel"
                            size="small" 
                            :label="props.confirmButtonLabel" 
                            :pt="{
                                root: { 
                                    style: {  border:'none', color:'white', backgroundColor:'red' } 
                                }
                            }"
                            @click="() => {
                                if (radioButtons && selectedValue !== null) {
                                    props.confirmFn?.(selectedValue);
                                } else {
                                    props.confirmFn?.();
                                }
                            }">
                        </Button>
                    </div>
                </div>
            </div>
        </template>
    </Toast>
</template>

<style lang="scss" scoped>
.toast-content {
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.5);
}

.mainContent {
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 300px;
    height: 100%;
    padding: 10px;
    background: white;
    border-radius: 10px;
}

.btnGroup {
    display: flex;
    justify-content: end;
    gap: 5px;
}

.radio-group {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 20px 0;
}

.radio-item {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1rem;

    input[type="radio"] {
        width: 24px;
        height: 24px;
    }

    label {
        font-size: 1rem;
    }
}
</style>
