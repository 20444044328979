const DIRECTORY_SOFTWARE_ROUTES = {
    path: 'directory/software',
    name: 'directory-software',
    redirect: { name: 'software-list' },
    component: () => import('@/views/pages/Directories/software/DirectorySoftwarePage.vue'),
    children: [
        {
            path: 'list',
            name: 'software-list',
            meta: { transition: 'slide-left' },
            component: () => import('@/views/pages/Directories/software/list/DirectorySoftwareListPage.vue')
        },
        {
            path: ':softwareId',
            name: 'current-software-software',
            meta: { transition: 'slide-right' },
            component: () => import('@/views/pages/Directories/software/current/DirectorySoftwareCurrentPage.vue'),
            children: [
                {
                    path: 'software',
                    name: 'current-software',
                    component: () => import('@/views/pages/Directories/software/current/tabs/basic/tab')
                },
                {
                    path: 'departments',
                    name: 'current-software-departments',
                    component: () => import('@/views/pages/Directories/software/current/tabs/departments/tab')
                }
            ]
        }
    ]
}

export default DIRECTORY_SOFTWARE_ROUTES;
