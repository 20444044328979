import http from "@/api/instance";
import type { BaseRequestType } from "@/api/requests/types";

type resetSettingsRequestType = BaseRequestType<{ data: { SettingType: string } }, void>;

export const resetSettings: resetSettingsRequestType = ({ params: { data }, cancelToken }) => {
    return http.httpCore.delete(`user/setting`, {
        params: data,
        cancelToken
    })
}